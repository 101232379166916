import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import WorkflowsCanvas from "./Canvas/WorkflowsCanvas";
import WorkflowsRightAside from "./RightAside/WorkflowsRightAside";
import { WorkflowEditorControls } from "./WorkflowsEditorControls";
import { Action, WorkflowNode, NodeId, Workflow } from "./types";
import { ActionMenu } from "./Canvas/CanvasHelpers";
import useApi from "@/hooks/useApi";
import LoadingSpinner from "@/components/LoadingSpinner";
import IconArrowBack3 from "@/icons/IconArrowBack3";
import IconEditPencil3 from "@/icons/IconEditPencil3";
import {
  PrimaryBlueButton,
  SecondaryGrayButton
} from "@velaro/velaro-shared/src/components/Buttons/Buttons";
import useToastAlert from "@/hooks/useToastAlert";
import { Severity } from "@velaro/velaro-shared/src/components/ToastAlert";
import { WorkflowContext } from "@/context/WorkflowContext";
import { getNodeOptions, updateNodeOption } from "./WorkflowNodeHelpers";

export type ConnectorData = {
  el: HTMLDivElement;
  parentNodeId: NodeId;
  parentOptionIndex?: number;
  childNodeId?: NodeId;
};

export type DraggingData = {
  event: React.DragEvent<HTMLDivElement>;
  nodeId: NodeId;
  draggedOverConnector?: ConnectorData;
};

export default function WorkflowsEditor() {
  const { id } = useParams();
  const api = useApi();
  const navigate = useNavigate();
  const toast = useToastAlert();
  const containerRef = useRef<HTMLDivElement>(null);
  const actionMenuRef = useRef<HTMLDivElement>(null);
  const [workflow, setWorkflow] = useState<Workflow | null>(null);
  const [loading, setLoading] = useState(true);
  const [scale, setScale] = useState(1);
  const [selectedNodeId, setSelectedNodeId] = useState<number | null>(null);
  const [selectedConnectorData, setSelectedConnectorData] =
    useState<ConnectorData | null>(null);
  const [draggingData, setDraggingData] = useState<DraggingData | null>(null);
  const [editingName, setEditingName] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    async function loadWorkflow() {
      const response = await api.messaging.get(`Workflows/${id}`);
      const json = await response.json();
      setLoading(false);
      if (json.status === 404) {
        console.error("Workflow not found");
        return;
      }
      setWorkflow(json);
    }

    if (loading) {
      loadWorkflow();
    }
  }, [api.messaging, id, loading, workflow]);

  const calcStartPos = useCallback(() => {
    const containerRect = document
      .getElementsByClassName("workflow-designer")[0]
      ?.getBoundingClientRect();

    const canvasRect = document
      .getElementById("canvas")
      ?.getBoundingClientRect();

    if (!containerRect || !canvasRect) {
      return;
    }

    const contMidX = containerRect?.width / 2;
    const canvasMidX = canvasRect.width / 2;
    let x = contMidX - canvasMidX;

    if (selectedNodeId != null) {
      const rightAsideOverlap = 200; //half the 400px width of the right aside
      x = x - rightAsideOverlap;
    }
    return { x, y: 20 };
  }, [selectedNodeId]);

  const resetTransform = useCallback(
    (setTransform: (x: number, y: number, scale: number) => void) => {
      const pos = calcStartPos();

      if (pos) {
        setTransform(pos.x, pos.y, scale || 1);
      }
    },
    [calcStartPos, scale]
  );

  const selectedNode = useMemo(() => {
    if (workflow == null || selectedNodeId == null) return undefined;
    return workflow.nodes[selectedNodeId];
  }, [selectedNodeId, workflow]);

  const actionMenuPos = useMemo(() => {
    if (!selectedConnectorData || !containerRef.current) return null;

    const connectorRect = selectedConnectorData.el.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    return {
      x: connectorRect.x - containerRect.x + 40,
      y: connectorRect.y - containerRect.y + 20
    };
  }, [selectedConnectorData]);

  useEffect(() => {
    const onClickAnywhere = () => {
      setSelectedConnectorData(null);
    };

    window.addEventListener("click", onClickAnywhere);

    return () => {
      window.removeEventListener("click", onClickAnywhere);
    };
  }, []);

  async function save(workflow: Workflow) {
    setSaving(true);
    try {
      await api.messaging.put(`Workflows`, workflow);
      toast.displayToast(Severity.Success, "Workflow saved");
      setIsDirty(false);
    } catch (e) {
      console.error(e);
      toast.displayToast(Severity.Error, "Failed to save workflow");
    }
    setSaving(false);
  }

  async function publish() {
    const clone = JSON.parse(JSON.stringify(workflow)) as Workflow;
    clone.enabled = true;
    updateWorkflow(clone);
    await save(clone);
  }

  function injectNewNode(
    actionType: Action,
    connectorData: ConnectorData | null
  ) {
    if (connectorData == null) {
      console.error("No selected connector data");
      return;
    }
    const node = {
      id: getLowestFreeId(),
      type: "action",
      actionType: actionType,
      text: "",
      child: connectorData.childNodeId
    } as WorkflowNode;

    injectNode(node, connectorData, workflow!);
    setSelectedConnectorData(null);
  }

  function injectNode(
    node: WorkflowNode,
    connectorData: ConnectorData,
    workingModel: Workflow
  ) {
    const clone = JSON.parse(JSON.stringify(workingModel)) as Workflow;
    const parentNode: WorkflowNode = clone.nodes[connectorData.parentNodeId];
    const options = parentNode.options || parentNode.conditionOptions || [];
    const optionIndex = connectorData.parentOptionIndex;
    const hasFailBranch =
      parentNode.actionType === "askQuestion" &&
      parentNode.questionType !== "multipleChoice" &&
      parentNode.invalidReplyAction === "failBranch";

    const hasElseBranch = parentNode.actionType === "condition";

    // If the parent node is an action node and has options, update the option
    //or update the failed branch child if it's a non multiple choice question node
    if (optionIndex !== undefined) {
      if (hasFailBranch) {
        if (optionIndex === 0) {
          //0 valid child
          parentNode.child = node.id;
        } else if (optionIndex === 1) {
          //1 invalid child
          parentNode.invalidChild = node.id;
        } else {
          throw new Error(
            "Invalid option index for non multiple choice question node"
          );
        }
      } else {
        if (hasElseBranch && optionIndex === options.length) {
          parentNode.fallbackChildId = node.id;
        } else {
          options[optionIndex] = {
            ...options[optionIndex],
            childNodeId: node.id
          };
        }
      }
    } else {
      //otherwise, just update the parent node's child
      parentNode.child = node.id;
    }

    clone.nodes[node.id] = node;
    updateWorkflow(clone);
    return clone;
  }

  function updateWorkflow(updatedWorkflow: Workflow) {
    setWorkflow(updatedWorkflow);
    setIsDirty(true);
  }

  function getLowestFreeId() {
    const ids = Object.keys(workflow!.nodes).map(Number);
    let i = 1;
    while (ids.includes(i)) {
      i++;
    }
    return i;
  }

  function onDragStart(nodeId: number, event: React.DragEvent<HTMLDivElement>) {
    event.stopPropagation();
    setDraggingData({ nodeId, event });
  }

  function onDragOverConnector(connectorData: ConnectorData) {
    if (!draggingData) {
      return;
    }
    setDraggingData({
      ...draggingData,
      draggedOverConnector: connectorData
    });
  }

  function onDragLeaveConnector() {
    if (!draggingData) {
      return;
    }
    setTimeout(() => {
      setDraggingData((prev) => {
        if (!prev) {
          return null;
        }
        return {
          ...draggingData,
          draggedOverConnector: undefined
        };
      });
    }, 10);
  }

  function onDragEnd(nodeId: number) {
    if (draggingData?.draggedOverConnector) {
      moveNode(nodeId, draggingData.draggedOverConnector);
    }
    setDraggingData(null);
  }

  function moveNode(nodeId: NodeId, connectorData: ConnectorData) {
    const node = { ...workflow!.nodes[nodeId] };

    if (
      connectorData.childNodeId === nodeId ||
      connectorData.parentNodeId === nodeId
    ) {
      return;
    }

    node.child = connectorData.childNodeId;

    const editedWorkflow = removeNode(nodeId);

    if (!editedWorkflow) {
      return;
    }
    injectNode(node, connectorData, editedWorkflow);
  }

  function removeNode(nodeIdToRemove: number) {
    const clone = JSON.parse(JSON.stringify(workflow)) as Workflow;
    const nodeToRemove = clone.nodes[nodeIdToRemove];
    if (!nodeToRemove) {
      return;
    }

    //find the node to remove's child
    //the child id is either the child property or the first option's childNodeId
    const nodeToRemoveOptions = getNodeOptions(nodeToRemove);
    const newChildId = nodeToRemoveOptions?.length
      ? nodeToRemoveOptions[0].childNodeId
      : nodeToRemove.child;

    //find the parent node and update its child
    const nodeIds = Object.keys(clone.nodes).map((x) => parseInt(x) as NodeId);
    nodeIds.forEach((nodeId: NodeId) => {
      const node = clone.nodes[nodeId];
      const nodeOptions = getNodeOptions(node);
      if (!nodeOptions?.length) {
        if (node.child === nodeIdToRemove) {
          node.child = newChildId;
          return;
        }
        return;
      }

      const optionIndex = nodeOptions.findIndex((x) => {
        return x.childNodeId === nodeIdToRemove;
      });

      if (optionIndex > -1) {
        updateNodeOption(optionIndex, newChildId, node);
      }
    });

    //remove the node
    delete clone.nodes[nodeIdToRemove];
    updateWorkflow(clone);
    return clone;
  }

  function copyNode(nodeIdToCopy: number) {
    const clone = JSON.parse(JSON.stringify(workflow)) as Workflow;
    const nodeToCopy = clone.nodes[nodeIdToCopy];
    if (!nodeToCopy) {
      return;
    }
    const newNodeId = getLowestFreeId();
    const newNode = { ...nodeToCopy, id: newNodeId };

    injectNode(
      newNode,
      { el: actionMenuRef.current!, parentNodeId: nodeToCopy.id },
      clone
    );
  }

  if (loading) return <LoadingSpinner />;

  if (!workflow) return <div>Workflow not found</div>;

  return (
    <WorkflowContext.Provider value={{ workflow }}>
      <div className="pb-4 mb-4 flex items-center justify-between">
        <span className="text-lg h-8 font-bold text-gray-800 flex gap-2 items-center">
          <div
            className="cursor-pointer"
            onClick={() => navigate("/workflows")}
          >
            <IconArrowBack3 />
          </div>
          <div className="group flex gap-2 items-center">
            <>
              {editingName && (
                <input
                  autoFocus
                  onBlur={() => setEditingName(false)}
                  onChange={(e) => {
                    const clone = { ...workflow };
                    clone.name = e.target.value;
                    updateWorkflow(clone);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") setEditingName(false);
                  }}
                  type="text"
                  value={workflow.name}
                  className="border-none bg-inherit p-1"
                />
              )}
              {!editingName && (
                <>
                  {workflow.name}
                  <div
                    onClick={() => setEditingName(true)}
                    className="cursor-pointer hidden group-hover:inline"
                  >
                    <IconEditPencil3 className="hover:stroke-blue-500" />
                  </div>
                </>
              )}
            </>
          </div>
        </span>
        <span className="flex gap-2">
          <SecondaryGrayButton
            label="Save as Draft"
            onClick={() => save(workflow)}
            disabled={saving}
          />
          <PrimaryBlueButton
            label="Publish"
            onClick={publish}
            disabled={saving}
          />
        </span>
      </div>
      <div ref={containerRef} className="relative flex overflow-hidden">
        <div className="flex overflow-hidden cursor-move border border-slate-200 rounded-3xl workflow-designer h-auto shrink relative">
          <TransformWrapper
            doubleClick={{ disabled: true }}
            minScale={0.1}
            maxScale={2}
            initialScale={1}
            limitToBounds={false}
            onZoom={(ref) => {
              setScale(ref.state.scale);
            }}
            zoomAnimation={{ disabled: true }}
          >
            {({ setTransform, ...utils }) => (
              <>
                <WorkflowEditorControls
                  zoomIn={(step) => {
                    const newScale = scale + step;
                    if (newScale > 2) return;
                    setScale(newScale);
                    utils.zoomIn(step);
                  }}
                  zoomOut={(step) => {
                    const newScale = scale - step;
                    if (newScale < 0.1) return;
                    setScale(newScale);
                    utils.zoomOut(step);
                  }}
                  scale={scale}
                  resetTransform={() => resetTransform(setTransform)}
                />
                <TransformComponent>
                  <WorkflowsCanvas
                    scale={scale}
                    nodes={workflow!.nodes}
                    draggingData={draggingData}
                    selectedNodeId={selectedNodeId}
                    selectedConnector={selectedConnectorData?.el}
                    onLoad={() => resetTransform(setTransform)}
                    onSelectNode={setSelectedNodeId}
                    onSelectConnector={setSelectedConnectorData}
                    onDeleteNode={removeNode}
                    onCopyNode={copyNode}
                    onDragStart={onDragStart}
                    onDragOverConnector={onDragOverConnector}
                    onDragLeaveConnector={onDragLeaveConnector}
                    onDragEnd={onDragEnd}
                  />
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
          {selectedNode && (
            <WorkflowsRightAside
              node={selectedNode}
              onUpdate={(updates) => {
                const newNode = { ...selectedNode, ...updates };
                const cloneWorkflow = JSON.parse(
                  JSON.stringify(workflow)
                ) as Workflow;
                cloneWorkflow.nodes[selectedNode.id] = newNode;
                if (newNode.type === "trigger") {
                  cloneWorkflow.triggerType = newNode.trigger;
                }
                updateWorkflow(cloneWorkflow);
              }}
              onClose={() => setSelectedNodeId(null)}
            />
          )}
        </div>
      </div>
      {actionMenuPos && (
        <div
          ref={actionMenuRef}
          onClick={(e) => e.stopPropagation()}
          className="absolute"
          style={{ top: actionMenuPos.y, left: actionMenuPos.x }}
        >
          <ActionMenu
            onSelect={(nodeId) => injectNewNode(nodeId, selectedConnectorData)}
          />
        </div>
      )}
    </WorkflowContext.Provider>
  );
}
